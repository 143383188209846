import cloneDeep from 'lodash/cloneDeep'
// import isNil from 'lodash/isNil'
import { v4 as uuidv4 } from 'uuid'

import { apiInvoice } from '@/api/'
import {
  DEFAULT_VAT_RATE, TAX_TYPES_OBJECT, DEFAULT_UNIT, FLIGHT_TYPE, GOODS_SERVICE_TYPES, INVOICE_LIST_COLUMNS,
} from '@/constants/invoice'

import { convertISODateTime } from '@core/utils/filter'

import { convertShortenTrips } from '@saleReport/useSaleReportHandle'

import { numberToFixed } from './useInvoiceHandle'

function getColumnsListFromLocalStorage() {
  return localStorage.getItem('columnsList')
}

function setColumnsListFromLocalStorage(val) {
  return localStorage.setItem('columnsList', JSON.stringify(val))
}
const COLUMNS_DEFAULT = INVOICE_LIST_COLUMNS.filter(col => col.default).map(col => col.key)
const initFiltersSaleReport = {
  startDate: convertISODateTime(new Date()).dateFilter,
  endDate: convertISODateTime(new Date()).dateFilter,
  bookingCode: '',
  ticketNumber: '',
  ticketType: '',
  paxName: '',
  airline: '',
  agencyCode: '',
  flightType: 'Domestic',
}
export default {
  namespaced: true,
  state: {
    selectedTickets: [], // for create
    totalGoodsAmount: 0,
    collectionFee: 0,
    needToPayAmount: 0,
    columnsList: getColumnsListFromLocalStorage() ?? COLUMNS_DEFAULT, // columns in list invoice
    filtersSaleReport: initFiltersSaleReport,
    invoiceTicketsForCreateInvoice: [], // for create invoice with selected invoice tickets
  },
  getters: {
    getSelectedTickets: state => state.selectedTickets, // sortBy(state.selectedTickets, 'flightType'),
    findSelectedTicket: state => ticket => state.selectedTickets.find(item => item.ticketNumber === ticket.ticketNumber),
    getTotalGoodsAmount: state => state.totalGoodsAmount,
    getCollectionFee: state => state.collectionFee,
    getTotalAmount: state => state.totalGoodsAmount + state.collectionFee,
    getNeedToPayAmount: state => state.needToPayAmount,
    getColumnsList: state => state.columnsList,
    getFiltersSaleReport: state => state.filtersSaleReport,
    getInvoiceTicketsForCreateInvoice: state => state.invoiceTicketsForCreateInvoice,
  },
  mutations: {
    SET_SELECTED_TICKET(state, value) {
      state.selectedTickets = value
    },
    CLEAR_SELECTED_TICKET(state) {
      state.selectedTickets = []
    },
    SET_TOTAL_GOODS_AMOUNT(state, value) {
      state.totalGoodsAmount = value
    },
    SET_COLLECTION_FEE(state, value) {
      state.collectionFee = value
    },
    SET_NEED_TO_PAY_AMOUNT(state, value) {
      state.needToPayAmount = value
    },
    SET_COLUMNS_LIST(state, value) {
      state.columnsList = value
      setColumnsListFromLocalStorage(value)
    },
    SET_FILTERS_SALE_REPORT(state, value) {
      state.filtersSaleReport = { ...state.filtersSaleReport, ...value }
    },
    SET_INVOICE_TICKETS_FOR_CREATE_INVOICE(state, value) {
      state.invoiceTicketsForCreateInvoice = value
    },
  },
  actions: {
    // ANCHOR LIST
    async fetchInvoices(_ctx, payload) {
      const res = await apiInvoice.searchInvoice(payload)
      return res
    },

    setColumnsList({ commit }, value) {
      commit('SET_COLUMNS_LIST', value)
    },

    exportInvoices(_ctx, payload, fileName = 'DanhSachHoaDon') {
      return apiInvoice.exportInvoices(payload, fileName)
    },

    // ANCHOR CREATE
    createInvoice(_ctx, payload) {
      return apiInvoice.createInvoice(payload)
    },

    async getCompanyByTaxCode(_ctx, taxCode) {
      const res = await apiInvoice.getCompanyByTaxCode(taxCode)
      return res
    },

    async getTemplateId(_ctx, id) {
      const res = await apiInvoice.getInvoiceTemplate(id)
      return res
    },

    getTags(_ctx, text) {
      return apiInvoice.getTags(text)
    },

    // ANCHOR SELECT TICKET
    setSelectedTicket({ getters, dispatch }, { item: ticketItem, valueAddedTax }) {
      if (!valueAddedTax) valueAddedTax = DEFAULT_VAT_RATE
      const findTicket = getters.findSelectedTicket(ticketItem)
      let selectedTickets = cloneDeep(getters.getSelectedTickets)
      if (findTicket) {
        selectedTickets = selectedTickets.filter(item => item.ticketNumber !== ticketItem.ticketNumber)
        // Xoá note nếu không còn ticket nào phù hợp với note
        if (!selectedTickets.some(ticket => ticket.flightType === ticketItem.flightType && ticket.itemType === ticketItem.itemType)) {
          selectedTickets = selectedTickets.filter(item => !(item.itemType === GOODS_SERVICE_TYPES.NOTE && item.flightType === ticketItem.flightType))
        }
      } else {
        const valueAddedTaxDefault = valueAddedTax
        const collectionFee = Number(ticketItem?.collectionFee ?? 0) + Number(ticketItem?.otherTax ?? 0)
        const amount = numberToFixed(ticketItem?.receivables ?? ticketItem?.unitPrice) - collectionFee
        const unitPrice = numberToFixed((amount / (100 + TAX_TYPES_OBJECT[valueAddedTaxDefault])) * 100)
        const vatAmount = Math.round(amount - unitPrice)
        const numberOfSegments = ticketItem?.ticketType === 'TKT' ? ticketItem?.trips?.split(' - ').length : 0
        const mappingItem = {
          id: ticketItem?.id,
          name: `${ticketItem?.ticketType === 'EMD' ? `${ticketItem?.ticketType} - ` : ''}${ticketItem?.trips ? convertShortenTrips(ticketItem.trips).join(', ') : (ticketItem?.ticketType ?? ticketItem?.flightTrip)}`,
          ticketNumber: ticketItem?.ticketNumber,
          itemType: GOODS_SERVICE_TYPES.GOODS_SERVICE,
          unit: DEFAULT_UNIT,
          quantity: 1,
          unitPrice,
          valueAddedTax: valueAddedTaxDefault,
          vatAmount,
          amount,
          originAmount: amount + collectionFee,
          collectionFee,
          numberOfSegments,
          flightType: ticketItem?.flightType ?? 'Other',
          isInvoiceTicket: ticketItem?.isInvoiceTicket ?? undefined,
        }
        // console.log({ mappingItem })

        // Nếu chưa có ghi chú (Nội địa/ quốc tế) thì thêm vào
        const findNoteFlightType = selectedTickets.find(item => item.name === FLIGHT_TYPE[mappingItem.flightType])
        if (!findNoteFlightType) {
          const noteItem = {
            id: uuidv4(),
            name: FLIGHT_TYPE[mappingItem.flightType],
            itemType: GOODS_SERVICE_TYPES.NOTE,
            ticketNumber: '',
            unit: GOODS_SERVICE_TYPES.NOTE,
            quantity: 0,
            unitPrice: 0,
            valueAddedTax: valueAddedTaxDefault,
            vatAmount: 0,
            amount: 0,
            originAmount: 0,
            collectionFee: 0,
            numberOfSegments: 0,
            flightType: ticketItem?.flightType,
          }
          selectedTickets.push(noteItem)
        }
        const findLastIndexOfItem = selectedTickets.findLastIndex(item => item.flightType === mappingItem.flightType)

        if (findLastIndexOfItem >= 0) {
          selectedTickets.splice(findLastIndexOfItem + 1, 0, mappingItem)
        } else {
          selectedTickets.push(mappingItem)
        }
      }
      dispatch('setSelectedTicketsInDetail', selectedTickets)
      dispatch('setCollectionFee', selectedTickets.reduce((acc, ticket) => acc + (ticket.collectionFee ?? 0), 0))
      dispatch('setTotalGoodsAmount', selectedTickets.reduce((acc, ticket) => acc + (ticket.amount ?? 0), 0))
      // dispatch('setNeedToPayAmount', 0)
    },

    addSelectedTicket({ dispatch, getters }, ticketItem) {
      const selectedTickets = cloneDeep(getters.getSelectedTickets)
      selectedTickets.push(ticketItem)
      dispatch('setSelectedTicketsInDetail', selectedTickets)
    },

    removeSelectedTicket({ dispatch, getters }, ticketItem) {
      let selectedTickets = cloneDeep(getters.getSelectedTickets)
      selectedTickets = selectedTickets.filter(ticket => ticket.id !== ticketItem.id)
      // Xoá note nếu không còn ticket nào phù hợp với note
      if (!selectedTickets.some(ticket => ticket.flightType === ticketItem.flightType && ticket.itemType === ticketItem.itemType)) {
        selectedTickets = selectedTickets.filter(item => !(item.itemType === GOODS_SERVICE_TYPES.NOTE && item.flightType === ticketItem.flightType))
      }
      dispatch('setSelectedTicketsInDetail', selectedTickets)
    },

    setFiltersSaleReport({ commit }, value) {
      commit('SET_FILTERS_SALE_REPORT', value)
    },

    resetFiltersSaleReport({ commit }) {
      commit('SET_FILTERS_SALE_REPORT', initFiltersSaleReport)
    },

    // reset store
    clearSelectedTickets({ commit, dispatch }) {
      commit('CLEAR_SELECTED_TICKET')
      dispatch('setCollectionFee', 0)
      dispatch('setTotalGoodsAmount', 0)
      dispatch('setNeedToPayAmount', 0)
    },

    setTotalGoodsAmount({ commit }, value) {
      commit('SET_TOTAL_GOODS_AMOUNT', value)
    },

    setCollectionFee({ commit }, value) {
      commit('SET_COLLECTION_FEE', value)
    },

    setNeedToPayAmount({ commit, getters }, value) {
      if (value === getters.getNeedToPayAmount) return
      commit('SET_NEED_TO_PAY_AMOUNT', Math.round(value))
    },

    // ANCHOR DETAIL
    getInvoiceById(_ctx, id) {
      return apiInvoice.getInvoiceById(id)
    },

    getTransmissionHistory(_ctx, id) {
      return apiInvoice.getTransmissionHistory(id)
    },

    setSelectedTicketsInDetail({ commit }, tickets) {
      commit('SET_SELECTED_TICKET', tickets)
    },

    // Ký
    signInvoice(_ctx, { id, payload }) { // bỏ để thay = sign-and-release
      return apiInvoice.signInvoice(id, payload)
    },

    signAndReleaseInvoice(_ctx, { id, payload }) {
      return apiInvoice.signAndReleaseInvoice(id, payload)
    },

    releaseBatch(_ctx, payload) {
      return apiInvoice.releaseBatch(payload)
    },

    getCountProcessingReleaseBatch(_ctx, payload) {
      return apiInvoice.getCountProcessingReleaseBatch(payload)
    },

    getCompanyCertificates(_ctx, { companyId, params }) {
      return apiInvoice.getCompanyCertificates(companyId, params)
    },

    // Gửi cơ quan thuế / phát hành
    releaseInvoice(_ctx, invoiceId) { // bỏ để thay = sign-and-release
      return apiInvoice.releaseInvoice(invoiceId)
    },

    getXmlInvoicesInBatch(_ctx, batchId) { // bỏ để thay = sign-and-release
      return apiInvoice.getXmlInvoicesInBatch(batchId)
    },

    // gui email
    sendMailInvoice(_ctx, invoiceId) {
      return apiInvoice.sendMailInvoice(invoiceId)
    },

    // dieu chinh hd
    adjustInvoice(_ctx, { id: invoiceId, payload }) {
      return apiInvoice.adjustInvoice(invoiceId, payload)
    },

    // thay the hd
    replaceInvoice(_ctx, { id: invoiceId, payload }) {
      return apiInvoice.replaceInvoice(invoiceId, payload)
    },

    // nhân bản hd
    duplicateInvoice(_ctx, { id: invoiceId }) {
      return apiInvoice.duplicateInvoice(invoiceId)
    },

    // huy hd
    cancelInvoice(_ctx, { id: invoiceId, payload }) {
      return apiInvoice.cancelInvoice(invoiceId, payload)
    },

    cancelBatchInvoice(_ctx, payload) {
      return apiInvoice.cancelBatchInvoice(payload)
    },

    // sua hd
    updateInvoice(_ctx, { id: invoiceId, payload }) {
      return apiInvoice.updateInvoice(invoiceId, payload)
    },

    // ANCHOR PREVIEW PDF
    getInvoicePdfById(_ctx, id) {
      return apiInvoice.getInvoicePdfById(id)
    },

    // SECTION: INVOICE TICKET
    getInvoiceTickets(_ctx, payload) {
      return apiInvoice.getInvoiceTickets(payload)
    },
    setInvoiceTicketsForCreateInvoice({ commit }, tickets) {
      commit('SET_INVOICE_TICKETS_FOR_CREATE_INVOICE', tickets)
    },
    // !SECTION
  },
}
