import { computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import store from '@/store'
import { statusTrainOptions } from '@/constants/train'
import { formatCurrency, roundNumberPriceTrain } from '@/@core/utils/filter'

import storeModule from '@train/trainStoreModule'

export default function useTrainHandle() {
  if (!store.hasModule('app-train')) {
    store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
  }

  // states
  const loading = computed(() => store.state['app-train'].loading)
  // const loadingGetSeatByCoach = computed(() => store.state['app-train'].loadingGetSeatByCoach)
  const loadingAppliesPromotions = computed(() => store.state['app-train'].loadingAppliesPromotions)
  const loadingPickTicket = computed(() => store.state['app-train'].loadingPickTicket)
  const loadingGetFares = computed(() => store.state['app-train'].loadingPickTicket)

  const tabIndex = computed(() => store.state['app-train'].tabIndex)
  const statePayloadSearch = computed(() => store.state['app-train'].payloadSearch)
  const stateResultSearch = computed(() => store.state['app-train'].resultSearchTrain) // (object) result search train

  const stateLoadingSetting = computed(() => store.state['app-train'].loadingSetting)
  const stateSetting = computed(() => store.state['app-train'].settings)

  const stateLoadingCategory = computed(() => store.state['app-train'].loadingCategory)
  const stateCategory = computed(() => store.state['app-train'].category)

  const stateTrainSelectedArray = computed(() => store.state['app-train'].trainSelectedArray) // (array) tàu đã chọn
  // const stateCoachSelectedArray = computed(() => store.state['app-train'].coachSelectedArray) // (array) khoang theo tàu
  const stateTicketSelectedArray = computed(() => store.state['app-train'].ticketSelectedArray) // (array-nested) vé đã chọn

  // getters
  const getListTrainStation = computed(() => store.getters['app-train/getListTrainStation']) // (array) list trạm dừng
  const getListPassengerTarget = computed(() => store.getters['app-train/getListPassengerTarget']) // (array) list
  const getListSeatGroup = computed(() => store.getters['app-train/getListSeatGroup']) // (array) list
  const getListSeatType = computed(() => store.getters['app-train/getListSeatType']) // (array) list
  const getSearchPayloadArray = computed(() => store.getters['app-train/getSearchPayloadArray']) // (array)

  const getBookingCode = computed(() => store.getters['app-train/getBookingCode']) // (string) bookingCode
  const getIsSearchRT = computed(() => store.getters['app-train/getIsSearchRT']) // (boolean)
  const getResultTrainArray = computed(() => store.getters['app-train/getResultTrainArray']) // (array nested) train result search
  const getTotalCartTicketPrice = computed(() => store.getters['app-train/getTotalCartTicketPrice']) // (number) totalPrice
  const getMaxNumberOfTicketOW = computed(() => store.getters['app-train/getMaxNumberOfTicketOW']) // (number) maximum ticket

  const getServiceFeeByPassengerTargetCode = code => store.getters['app-train/getServiceFeeByPassengerTargetCode'](code)

  // fetch category
  if ((!stateCategory.value || isEmpty(getListTrainStation.value)) && !stateLoadingCategory.value) {
    store.dispatch('app-train/handleGetCategory')
  }
  // fetch setting
  if (!stateSetting.value && !stateLoadingSetting.value) {
    store.dispatch('app-train/handleGetSetting')
  }

  function dropTicketHandle(ticketLists) {
    const availTickets = ticketLists.filter(tk => !tk.isExpired)
    if (isEmpty(availTickets)) return
    const payload = {
      listTicket: ticketLists.map(ticket => ({
        dateOfMonth: ticket.dateOfMonth,
        ticketId: ticket.ticketId,
        bookingCode: getBookingCode.value,
        direction: 1,
        trainId: ticket.trainId,
        coachId: ticket.coachId,
        seatId: ticket.seatId,
        physicalTrainId: ticket.physicalTrainId,
      })),
    }
    store.dispatch('app-train/dropTicket', payload)
  }

  async function searchTrain(payload) {
    const tickets = stateTicketSelectedArray.value?.flat() || []
    dropTicketHandle(tickets)
    const res = await store.dispatch('app-train/searchTrain', payload)
    return res
  }

  function handleSetTabIndex(index) {
    if (index === tabIndex.value) return
    store.commit('app-train/SET_TAB_INDEX', index)
  }

  function handleClickSeat(resTicket) {
    const train = stateTrainSelectedArray.value[tabIndex.value]
    // const coach = stateCoachSelectedArray.value[tabIndex.value]

    const ticket = {
      fullName: '',
      identifyNumber: '',
      passengerTargetCode: 1,
      isExpired: false,

      duration: resTicket.duration, // res
      ticketId: resTicket.ticketId, // res
      trainNumber: resTicket.trainNumber, // res
      dayOfStart: resTicket.dayOfStart, // res
      coachName: resTicket.coachName, // res
      coachCode: resTicket.coachCode, // res
      seatName: resTicket.seatNumber, // res
      seatNumber: resTicket.seatNumber, // res
      seatTypeCode: resTicket.seatTypeCode, // res
      ticketPrice: resTicket.ticketPrice, // res
      totalPrice: resTicket.totalPrice, // res
      insurancePrice: resTicket.insurancePrice, // res
      seatId: resTicket.seatId, // res
      coachId: resTicket.coachId, // res
      seatKeys: resTicket.seatKeys, // res
      seatTypeId: resTicket.seatTypeId, // res

      refTicketId: 0,
      isReturn: tabIndex.value === 1,
      departure: train.departure,
      departureKM: train.departureKM,
      departureHour: train.departureHour,
      departureTime: train.departureTime,
      arrival: train.arrival,
      arrivalKM: train.arrivalKM,
      arrivalTime: train.arrivalTime,
      dateOfMonth: train.dateOfMonth,
      physicalTrainId: train.physicalTrainId,
      trainCode: train.trainCode,
      trainId: train.id,
      trainKeys: train.trainKeys,
      discountPassenger: 0,
      discountPromotion: 0,
      commission: 0,
      voucherCode: '',

      // basePrice: seat.basePrice,
      // coachNumber: coach.coachNumber,
      // seatGroupCode: coach.seatGroupCode,
    }

    store.dispatch('app-train/handleToggleSelectTicket', { ticket })
  }

  function resolveDataSeat(availSeats, seatName, itineraryIndex) {
    const seatData = availSeats.find(seat => seat.seatNumber && Number(seat.seatNumber) === seatName)
    return {
      nameId: seatName,
      itineraryIndex,
      hidden: !seatData,
      notExist: !seatData || seatData?.seatStatus?.status?.id !== 3,
      ...seatData,
    }
  }

  function isAvailableSeat(seat) {
    if (seat?.notExist || !seat?.totalPrice) return false
    const storeExist = stateTicketSelectedArray.value[seat.itineraryIndex].find(it => it.coachId === seat.coachId && it.seatId === seat.id)
    if (seat?.seatStatus?.status?.id === 3 && !storeExist) return true
    return false
  }

  function resolveVariantSeat(seat) {
    if (seat?.notExist || !seat?.totalPrice) return 'danger'
    const storeExist = stateTicketSelectedArray.value[seat.itineraryIndex].find(it => it.coachId === seat.coachId && it.seatId === seat.id)
    if (storeExist) return 'success'
    if (seat?.seatStatus?.status?.id === 3) return 'secondary'
    return 'danger'
  }

  function resolveStatus(seat) {
    if (seat?.notExist || !seat?.totalPrice) return 'unavailable'
    const storeExist = stateTicketSelectedArray.value[seat.itineraryIndex].find(it => it.coachId === seat.coachId && it.seatId === seat.id)
    if (storeExist) return 'selected'
    if (seat?.seatStatus?.status?.id === 3) return 'available'
    return 'unavailable'
  }

  function hasAvailSeatOnCarriage(availSeats, carriage, itineraryIndex) {
    return carriage.data.find(rows => rows.find(seat => !resolveDataSeat(availSeats, seat, itineraryIndex).hidden))
  }

  function resolveDiscountPriceByPassengerCode(ticketPrice, paxTypeCode) {
    const passengerType = getListPassengerTarget.value.find(item => item.code === paxTypeCode)
    if (!passengerType) return 0
    const discountedPrice = ticketPrice - (ticketPrice * (passengerType.ratio / 100))
    return roundNumberPriceTrain(discountedPrice)
  }

  function resolveTotalPriceTicket(ticket, discountPaxType = true) {
    const totalPrice = ticket.applyPromoData ? ticket.applyPromoData.calcPromotion.totalPrice : (ticket.totalPrice - (discountPaxType ? resolveDiscountPriceByPassengerCode(ticket.ticketPrice, ticket.passengerTargetCode) : 0))
    const servicesFee = getServiceFeeByPassengerTargetCode(ticket.passengerTargetCode)
    const priceWithServiceFee = totalPrice + servicesFee
    return formatCurrency(priceWithServiceFee || 0)
  }

  function deleteTicketHandle(storeIndex, ticket, showModal = true) {
    // if (!showModal) {
    //   store.dispatch('app-train/handleToggleSelectTicket', { storeIndex, ticket })
    //   return
    // }

    Vue.swal({
      // title: 'Bạn thực sự muốn bỏ vé này?',
      html: `
        <span class="fw-700 font-medium-2">
          Bạn thực sự muốn xoá vé này?
          </span>
        `,
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Quay lại',
      confirmButtonText: 'Xoá!',
      animation: false,
      customClass: {
        confirmButton: 'btn btn-outline-danger fw-700',
        cancelButton: 'btn btn-outline-secondary fw-700 mr-50',
      },
      buttonsStyling: false,
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        dropTicketHandle([ticket])
        store.dispatch('app-train/handleToggleSelectTicket', { storeIndex, ticket })
      }
    })
  }

  function deleteAllSelectTicket() {
    Vue.swal({
      // title: 'Xoá tất cả vé đã chọn?',
      html: `
        <span class="fw-700 font-medium-2 text-danger">
          Xoá tất cả vé đã chọn ?
          </span>
        `,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Quay lại',
      confirmButtonText: 'Xoá hết!',
      customClass: {
        confirmButton: 'btn btn-outline-danger fw-700',
        cancelButton: 'btn btn-outline-secondary fw-700 mr-50',
      },
      buttonsStyling: false,
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        dropTicketHandle(stateTicketSelectedArray.value.flat())
        store.commit('app-train/SET_INITIAL_SELECT_TICKET')
      }
    })
  }

  const defaultDeparture = {
    id: 55,
    code: 'HNO',
    name: 'Hà Nội',
    searchKeys: 'ha noi, hn, hanoi, HNO, Hà Nội, Ha Noi',
  }

  const defaultArrival = {
    id: 129,
    code: 'SGO',
    name: 'Sài Gòn',
    searchKeys: 'sai gòn, saigon, sai gon, hcm, sg, hồ chí minh, ho chi minh, hochiminh, SGO, Sài Gòn, Sai Gon',
  }

  const blankCustomer = {
    id: null,
    firstName: '',
    lastName: '',
    fullName: '',
    phone: '',
    email: '',
    identifyNumber: '',
  }

  function getAirportName(code) {
    const station = getListTrainStation.value.find(it => it.code === code)
    return station?.name || code
  }

  function resolveNameByListSeatType(code) {
    return getListSeatType.value.find(it => it.code === code)?.description || ''
  }

  function getCoachNameByGroupCode(code) {
    return getListSeatGroup.value?.find(it => it?.code === code)?.name || ''
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  function changeCoaches(isNext) {
    store.commit('app-train/CHANGE_COACH', isNext)
  }

  return {
    defaultDeparture,
    defaultArrival,
    blankCustomer,

    loading,
    // loadingGetSeatByCoach,
    loadingAppliesPromotions,
    loadingPickTicket,
    loadingGetFares,
    tabIndex,
    stateSetting,
    stateCategory,
    stateResultSearch,
    stateLoadingCategory,
    statePayloadSearch,
    stateTrainSelectedArray,
    // stateCoachSelectedArray,
    stateTicketSelectedArray,

    getBookingCode,
    getIsSearchRT,
    getListTrainStation,
    getListPassengerTarget,
    getListSeatGroup,
    getListSeatType,
    getSearchPayloadArray,
    getResultTrainArray,
    getTotalCartTicketPrice,
    getMaxNumberOfTicketOW,

    delay,
    searchTrain,
    handleSetTabIndex,
    handleClickSeat,
    resolveDataSeat,
    resolveVariantSeat,
    resolveStatus,
    isAvailableSeat,
    deleteTicketHandle,
    getAirportName,
    resolveNameByListSeatType,
    deleteAllSelectTicket,
    resolveDiscountPriceByPassengerCode,
    getCoachNameByGroupCode,
    changeCoaches,
    getServiceFeeByPassengerTargetCode,
    resolveTotalPriceTicket,
    hasAvailSeatOnCarriage,
    dropTicketHandle,
  }
}

export const resolveTrainTicketStatusVariant = status => {
  const item = statusTrainOptions.find(item => item.value === status)
  return item?.color ?? 'primary'
}

export const getPassengerTypeByPassengerTargetCode = code => {
  const passengerType = store.getters['app-train/getPassengerTypeByPassengerTarget'](code)
  return passengerType ? `${passengerType.name}${passengerType.group.includes('NuocNgoai') ? ' (Nước ngoài)' : ''}` : null
}
