<template>
  <b-modal
    id="modal-sign-invoice"
    size="lg"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-1 modal-sign-invoice"
    title-class="h2 font-weight-bolder text-dark"
    :title="isBatch ? $t('invoice.releaseBatch.title') : $t('invoice.btn.signAndReleaseInvoice')"
    hide-footer
    hide-header-close
    @show="onShowHandle"
    @hide="onHideHandle"
  >
    <BAlert
      v-if="isProcessingReleaseBatch"
      show
      variant="danger"
      class="px-2 py-50"
    >
      {{ $t('invoice.releaseBatch.processingError') }}
    </BAlert>
    <div class="d-flex-center align-items-start flex-column gap-2 mb-50">
      <b>{{ isBatch ? $t('invoice.releaseBatch.invoiceChosen') : $t('invoice.signModal.invoiceChosen') }}: </b>
      <b-table
        ref="refSignAndReleaseBatchInvoiceListTable"
        style="max-height: 60vh"
        class="mb-0 position-relative"
        :sticky-header="true"
        :items="isBatch ? invoicesToSignAndRelease: [invoiceData]"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        bordered
        :fields="invoicesToSignAndReleaseTableColumns"
      >
        <template
          v-for="column in invoicesToSignAndReleaseTableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.key"
            class="text-nowrap"
          >
            {{ $t(`invoice.columns.${data.column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(symbol)="{ item }">
          {{ item.templateNumber }}{{ item.symbol }}
        </template>

        <template #cell(invNumber)="{ item }">
          <div class="text-dark fw-700">
            {{ item.invNumber }}
          </div>
        </template>

        <template #cell(createdAt)="{ item }">
          <div class="">
            {{ convertISODateTime(item.createdAt).date }}
          </div>
        </template>
      </b-table>

      <b v-if="invoicesNotToSignAndRelease?.length && isBatch">
        {{ $t('invoice.releaseBatch.invoiceCannotRelease') }} <b class="text-info fw-700">({{ invoicesNotToSignAndRelease?.length }})</b> -
        <span
          v-for="(groupByInvoiceNotToCancelItem, groupByInvoiceNotToCancelIndex) in groupBy(invoicesNotToSignAndRelease, 'reason')"
          :key="groupByInvoiceNotToCancelIndex"
        >
          <span
            v-for="(invoiceNotToCancelItem, invoiceNotToCancelIndex) in groupByInvoiceNotToCancelItem"
            :key="invoiceNotToCancelIndex"
          >
            <span class="fw-700 font-italic text-danger">{{ invoiceNotToCancelItem.invNumber || '&lt;Hoá đơn chưa có số&gt;' }}</span>
            <span v-if="invoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1">, </span>
            <span
              v-else
              class="font-weight-light font-italic"
            > ({{ $t(`invoice.msg.${invoiceNotToCancelItem.reason}`) }})</span>
          </span>

          <span v-if="groupByInvoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1"> ; </span>
        </span>
      </b>
    </div>

    <div>
      <b-form-group label-for="signType">
        <template #label>
          <b class="text-16px">
            {{ $t('invoice.signModal.signType') }}
            <span class="text-danger">(*)</span>
          </b>
        </template>
        <v-select
          id="signType"
          v-model="signType"
          :name="$t('invoice.signModal.signType')"
          :options="SIGN_TYPE_OPTIONS"
          label="label"
          :clearable="false"
          :reduce="(val) => val.value"
          :placeholder="$t('invoice.signModal.phSignType')"
        >
          <template #option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #selected-option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>

      <!-- ANCHOR: SIGN_TYPE.FILE -->
      <div v-if="!isToken">
        <b-table
          ref="refTable"
          :items="certificatesList"
          :fields="tableColumns"
          responsive
          small
          hover
          no-border-collapse
          show-empty
          :empty-text="$t('noMatchingResult')"
          :busy="isLoading"
          select-mode="single"
          selectable
          @row-clicked="onRowSelected"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]
          >
            <div
              :key="column.key"
              class="text-dark d-flex-center"
            >
              {{ $t(`invoice.signModal.columns.${column.key}`) }}
            </div>
          </template>
          <template #table-busy>
            <div class="d-flex-center text-dark my-2 gap-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #cell(checkbox)="row">
            <b-form-checkbox
              :checked="row.item.serial === certificateSelected"
              @change="onRowSelected(row.item)"
            />
          </template>

          <template #cell(organizationName)="row">
            {{ row.item.organizationName }}
          </template>

          <template #cell(serial)="row">
            <code>
              {{ row.item.serial }}
            </code>
          </template>

          <template #cell(startDate)="row">
            {{ convertISODateTime(row.item.startDate).date }}
          </template>

          <template #cell(endDate)="row">
            {{ convertISODateTime(row.item.endDate).date }}
          </template>
        </b-table>
      </div>
      <!-- ANCHOR: SIGN_TYPE.USB -->
      <div v-else>
        <b-alert
          v-if="!isPluggingInUSB"
          show
          variant="warning"
          class="px-2 py-1"
        >
          Vui lòng kết nối USB Token và bật phần mềm đọc token!
        </b-alert>
        <div v-else>
          <b-table
            ref="refTable"
            :items="certificatesList"
            :fields="tableColumns"
            responsive
            small
            hover
            no-border-collapse
            sticky-header
            show-empty
            :empty-text="$t('noMatchingResult')"
            :busy="isLoading"
            select-mode="single"
            selectable
            @row-clicked="onRowSelected"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column.key})`]
            >
              <div
                :key="column.key"
                class="text-dark d-flex-center"
              >
                {{ $t(`invoice.signModal.columns.${column.key}`) }}
              </div>
            </template>
            <template #table-busy>
              <div class="d-flex-center text-dark my-2 gap-2">
                <b-spinner class="align-middle" />
                <strong>{{ $t('loading') }}</strong>
              </div>
            </template>

            <template #cell(checkbox)="row">
              <b-form-checkbox
                :checked="row.item.serial === certificateSelected"
                @change="onRowSelected(row.item)"
              />
            </template>

            <template #cell(organizationName)="row">
              {{ row.item?.Subject || row.item?.subject }}
            </template>

            <template #cell(serial)="row">
              <code>
                {{ row.item.serial }}
              </code>
            </template>

            <template #cell(startDate)="row">
              {{ convertISODateTime(row.item.startDate).date }}
            </template>

            <template #cell(endDate)="row">
              {{ convertISODateTime(row.item.endDate).date }}
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- ANCHOR footer button -->
    <div class="d-flex-between mt-1">
      <BButton
        variant="secondary"
        @click="onCloseHandle"
      >
        {{ $t('close') }}
      </BButton>
      <BButton
        variant="primary"
        :disabled="!certificateSelected || isProcessingReleaseBatch || (isBatch && !invoicesToSignAndRelease.length)"
        @click="onSubmitHandle"
      >
        {{ $t('submit') }}
      </BButton>
    </div>
  </b-modal>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import isNil from 'lodash/isNil'
import groupBy from 'lodash/groupBy'

import {
  SIGN_TYPE_OPTIONS, SIGN_TYPE, RELEASE_STATUS_VALUES, INVOICE_STATUS_VALUES,
  BATCH_TYPE,
} from '@/constants/invoice'
import { convertISODateTime } from '@/@core/utils/filter'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import { apiInvoice } from '@/api'
import store from '@/store'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BTable,
    BSpinner,
    BAlert,
    BFormCheckbox,
    vSelect: () => import('vue-select'),
  },

  props: {
    companyId: {
      type: [Number, null],
      default: null,
    },
    invoiceData: {
      type: [Object, null],
      default: () => {},
    },
    isBatch: {
      type: Boolean,
      default: false,
    },
    checkedItems: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const { companyId, invoiceData } = toRefs(props)
    const { toastError, toastSuccess } = useToast()

    const signType = ref(null)
    const certificatesList = ref([])
    const certificateSelected = ref()

    const tableColumns = ref([
      { key: 'checkbox', stickyColumn: true },
      { key: 'organizationName' },
      { key: 'serial' },
      { key: 'startDate' },
      { key: 'endDate' },
    ])

    const {
      getCompanyCertificates,
      // signInvoice,
      signAndReleaseInvoice,
      releaseBatch,
      getCountProcessingReleaseBatch,
      loadingGetCompanyCertificates,
      getXmlInvoicesInBatch,
      // releaseInvoice,
    } = useInvoiceHandle()

    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const generateInvoiceNumberOnCreation = computed(() => agencyData.value?.company?.generateInvoiceNumberOnCreation ?? false)

    const invoicesToSignAndRelease = ref([])
    const invoicesNotToSignAndRelease = ref([])

    async function getCertificateListByFile() {
      if (isNil(companyId.value)) {
        toastError('invoice.msg.companyNotFound')
        return
      }
      certificatesList.value = await getCompanyCertificates(companyId.value, { type: SIGN_TYPE.FILE })
    }

    const isToken = computed(() => signType.value === SIGN_TYPE.USB)
    const isPluggingInUSB = ref(false)

    const isProcessingReleaseBatch = ref(false)
    const intervalId = ref()
    const xmlInvoice = ref()

    // ANCHOR: USB handle
    async function handleUsbToken() {
      if (isNil(companyId.value)) {
        toastError('invoice.msg.companyNotFound')
        return
      }

      const resGetCompanyCertificates = await getCompanyCertificates(companyId.value, { type: SIGN_TYPE.USB })
      const certificatesTypeUSB = resGetCompanyCertificates?.map(item => item.serial)

      if (!props.isBatch) {
        const invoiceId = invoiceData?.value?.id
        if (!invoiceId) {
          toastError('lỗi invoiceId')
          return
        }
        root.$bvModal.show('modal-api-loading')
        try {
          const resGetXmlInvoice = await apiInvoice.getXmlInvoice(invoiceId)
          xmlInvoice.value = resGetXmlInvoice.data
        } finally {
          root.$bvModal.hide('modal-api-loading')
        }
      }

      async function pullingGetCertsByToken() {
        try {
          const res = await apiInvoice.getCertsByToken()
          certificatesList.value = res.data?.data.filter(item => certificatesTypeUSB.includes(item.serial)) ?? []
          if (certificatesList.value.length) {
            isPluggingInUSB.value = true
            clearInterval(intervalId.value)
          } else if (res.data?.data.length) {
            isPluggingInUSB.value = false
            toastError('Chứng thư số trong USB chưa được cài đặt. Vui lòng cài đặt chứng thư số có trong USB và thử lại')
            clearInterval(intervalId.value)
          }
        } catch (error) {
          isPluggingInUSB.value = false
          clearInterval(intervalId.value)
        }
      }
      intervalId.value = setInterval(pullingGetCertsByToken, 1000)
    }

    watch(signType, () => {
      if (signType.value === SIGN_TYPE.FILE) {
        getCertificateListByFile()
        if (intervalId.value) {
          clearInterval(intervalId.value)
        }
      }
      else if (signType.value === SIGN_TYPE.USB) {
        handleUsbToken()
      }
    }, { immediate: true })

    function onCloseHandle() {
      root.$bvModal.hide('modal-sign-invoice')
    }

    async function onSubmitSingleHandle() {
      const invoiceId = invoiceData?.value?.id
      if (!invoiceId || !certificateSelected.value) return
      root.$bvModal.show('modal-api-loading')
      let res
      if (isPluggingInUSB.value) {
        if (!xmlInvoice.value || !certificateSelected.value) {
          toastError('Lỗi xml hoặc serial hoá đơn')
          root.$bvModal.hide('modal-api-loading')
          return
        }
        const payload = {
          serial: certificateSelected.value,
          type: 2,
          base64XML: xmlInvoice.value,
        }
        try {
          const signedInvoiceXML = await apiInvoice.signInvoiceLocal(payload)
          res = await apiInvoice.signCompleteInvoiceByToken(invoiceId, {
            signedData: signedInvoiceXML?.data?.data ?? signedInvoiceXML?.data,
          })
        } catch (error) {
          toastError('invoice.msg.signError')
          root.$bvModal.hide('modal-api-loading')
          return
        }
      } else {
        try {
          const payload = {
            serial: certificateSelected.value,
          }
          res = await signAndReleaseInvoice(invoiceId, payload)
        } catch (error) {
          toastError('invoice.msg.signAndReleaseError')
          root.$bvModal.hide('modal-api-loading')
          return
        }
      }
      if (res?.id) {
        // if (isPluggingInUSB.value) {
        //   await releaseInvoice(res?.id)
        // }
        toastSuccess('invoice.msg.signAndReleaseSuccess')
        emit('refetch')
        onCloseHandle()
        root.$bvModal.hide('modal-api-loading')
      } else {
        toastError('invoice.msg.signAndReleaseError')
        root.$bvModal.hide('modal-api-loading')
      }
    }

    async function onSubmitBatchHandle() {
      if (isPluggingInUSB.value) {
        root.$bvModal.show('modal-api-loading')
        // phát hành lô = usb lấy về batchId
        const payloadReleaseBatch = {
          certType: signType.value, // FILE, USB
          batchType: generateInvoiceNumberOnCreation.value
            ? BATCH_TYPE.GENERATE_INVOICE_NUMBER_AT_START
            : BATCH_TYPE.GENERATE_INVOICE_NUMBER_AT_RELEASE,
          issueInvoiceItems: invoicesToSignAndRelease.value.map((item, index) => ({
            id: item.id,
            externalId: item.externalId,
            ordinalNumber: index,
          })),
          serial: certificateSelected.value,
        }
        let batchId
        try {
          batchId = await releaseBatch(payloadReleaseBatch)
          console.log({ batchId })
        } catch (error) {
          toastError('Lỗi phát hành theo lô')
          root.$bvModal.hide('modal-api-loading')
          return
        }

        // có BatchId thì lấy XML
        if (!batchId) {
          toastError('Lỗi phát hành theo lô')
          root.$bvModal.hide('modal-api-loading')
          return
        }

        const { data: docXmls } = await getXmlInvoicesInBatch(batchId)
        console.log({ docXmls })
        if (!docXmls) {
          toastError('Lỗi lấy XML hoá đơn')
          root.$bvModal.hide('modal-api-loading')
          return
        }
        // có XML rồi thì gửi ký = USB TOKEN
        const payloadSignBatchUsb = {
          serial: certificateSelected.value,
          invoiceItems: docXmls.map(xml => ({
            invoiceId: xml.invoiceId,
            invoiceXmlData: xml.invoiceXmlData,
          })),
        }

        let signedBatchResponse
        try {
          signedBatchResponse = await apiInvoice.signBatchInvoiceLocal(payloadSignBatchUsb)
          console.log({ signedBatchResponse })
        } catch (error) {
          toastError('Lỗi Ký lô hoá đơn bằng USB')
        }

        // update portal = sign-complete
        const signCompletePayload = {
          batchId,
          signedDataList: signedBatchResponse
            ? signedBatchResponse.data.data.map(item => ({
              externalId: item.invoiceId,
              signedData: item.signedData?.data ?? item.signedData,
            }))
            : payloadSignBatchUsb.invoiceItems.map(item => ({
              externalId: item.invoiceId,
              signedData: '',
            })),
        }

        const signCompleteResponse = await apiInvoice.signCompleteBatch(signCompletePayload)
        console.log({ signCompleteResponse })

        if (signCompleteResponse && signedBatchResponse) {
          toastSuccess('Thành công')
        } else if (!signCompleteResponse && signedBatchResponse) {
          toastError('Lỗi cập nhật hoá đơn bằng USB')
        }
        onCloseHandle()
        root.$bvModal.hide('modal-api-loading')
      }
      else {
        root.$bvModal.show('modal-api-loading')
        try {
          const payload = {
            certType: signType.value, // FILE, USB
            batchType: generateInvoiceNumberOnCreation.value ? BATCH_TYPE.GENERATE_INVOICE_NUMBER_AT_START : BATCH_TYPE.GENERATE_INVOICE_NUMBER_AT_RELEASE,
            issueInvoiceItems: invoicesToSignAndRelease.value.map((item, index) => ({
              id: item.id,
              externalId: item.externalId,
              ordinalNumber: index,
            })),
            serial: certificateSelected.value,
          }
          await releaseBatch(payload)
          emit('refetch')
          onCloseHandle()
          root.$bvModal.hide('modal-api-loading')
        } catch (error) {
          toastError('invoice.msg.signAndReleaseError')
          root.$bvModal.hide('modal-api-loading')
        }
      }
    }

    function onSubmitHandle() {
      if (props.isBatch) {
        onSubmitBatchHandle()
      } else {
        onSubmitSingleHandle()
      }
    }

    // check `Hóa đơn có thuộc cấu hình sinh số hiện tại ko?`
    const checkGenerateInvoiceNumberOnCreationWithInvNumber = invoice => generateInvoiceNumberOnCreation.value !== !!invoice.invNumber
    async function onShowHandle() {
      signType.value = SIGN_TYPE.FILE
      xmlInvoice.value = null
      intervalId.value = null
      isPluggingInUSB.value = null
      certificateSelected.value = null
      certificatesList.value = []
      isProcessingReleaseBatch.value = false

      invoicesNotToSignAndRelease.value = props.checkedItems
        .filter(invoiceItem => {
          if (invoiceItem.releaseStatus !== RELEASE_STATUS_VALUES.NEW
          || invoiceItem.invoiceStatus !== INVOICE_STATUS_VALUES.NEW) {
            invoiceItem.reason = 'WRONG_STATUS'
            return true
          }
          if (checkGenerateInvoiceNumberOnCreationWithInvNumber(invoiceItem)) {
            invoiceItem.reason = 'DIFFERENCE_GENERATE_INVOICE_NUMBER_ON_CREATION'
            return true
          }
          return false
        })
        .map(invoiceItem => ({ invNumber: invoiceItem.invNumber, reason: invoiceItem.reason }))
        .sort((a, b) => a.invNumber > b.invNumber ? 1 : -1)
      invoicesToSignAndRelease.value = props.checkedItems
        .filter(invoiceItem => invoiceItem.releaseStatus === RELEASE_STATUS_VALUES.NEW
        && invoiceItem.invoiceStatus === INVOICE_STATUS_VALUES.NEW
        && !checkGenerateInvoiceNumberOnCreationWithInvNumber(invoiceItem))
    }

    watch(() => props.isBatch, async () => {
      if (props.isBatch) {
        const countProcessing = await getCountProcessingReleaseBatch()
        isProcessingReleaseBatch.value = countProcessing.data !== 0
      }
    })

    const invoicesToSignAndReleaseTableColumns = [
      { label: 'no', key: 'no' },
      { label: 'symbol', key: 'symbol' },
      { label: 'invNumber', key: 'invNumber' },
      { label: 'createdAt', key: 'createdAt' },
    ]

    const refTable = ref()
    function onRowSelected(item) {
      if (certificateSelected.value === item?.serial) {
        certificateSelected.value = null
        return
      }
      certificateSelected.value = item?.serial ?? null
    }
    function onHideHandle() {
      signType.value = null
      clearInterval(intervalId.value)
      emit('update:isBatch', false)
    }

    const isLoading = computed(() => loadingGetCompanyCertificates.value)
    return {
      invoicesToSignAndRelease,
      invoicesNotToSignAndRelease,
      invoicesToSignAndReleaseTableColumns,
      onSubmitHandle,
      onCloseHandle,
      onShowHandle,
      signType,
      SIGN_TYPE_OPTIONS,
      SIGN_TYPE,
      isToken,
      certificatesList,
      isLoading,
      tableColumns,
      convertISODateTime,
      onRowSelected,
      certificateSelected,
      refTable,
      isPluggingInUSB,
      onHideHandle,
      groupBy,
      isProcessingReleaseBatch,
    }
  },
}
</script>

<style lang="scss">
  .modal-sign-invoice {
    .table {
      thead th {
        vertical-align: middle;
      }

      &.b-table {
        >tbody {
          .b-table-row-selected {
            &.table-active {
              td {
                background-color: white;
                color: black;
              }
            }
          }
        }
      }
    }
  }
</style>
